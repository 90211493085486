import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonLabel, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Rooms from './pages/Rooms/Rooms';
import Sensors from './pages/Sensors/Sensors';
import Values from './pages/Values/Values';
import TimeSettings from './pages/TimeSettings/TimeSettings';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route path="/" exact={true}>
          <Redirect to="/rooms" />
        </Route>
        <Route path="/rooms/:roomId/sensors/:sensorId/values" exact={true}>
          <Values />
        </Route>
        <Route path="/rooms/:roomId" exact={true}>
          <Sensors />
        </Route>
        <Route path="/rooms" exact={true}>
          <Rooms />
        </Route>
        <Route path="/time_settings" exact={true}>
          <TimeSettings />
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
