import ListItem from '../../components/ListItem/ListItem';
import { useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonList,
  IonLoading,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter
} from '@ionic/react';
import './Rooms.css';
import { ApiClient } from '../../helpers';
import { useHistory } from 'react-router';

const Rooms = () => {

  const history = useHistory();
  const [rooms, setRooms] = useState(null);

  useIonViewWillEnter(refresh);
  
  function refresh() {
    ApiClient.get('rooms')
      .then(({rooms}) => setRooms(rooms));
  }

  function onRoomClick(roomId) {
    ApiClient.get(`/rooms/${roomId}/sensors`)
    .then(({ sensors }) => {
      if(sensors.length === 1) {
        history.push(`/rooms/${roomId}/sensors/${sensors[0].id}/values`);
      } else {
        history.push(`/rooms/${roomId}`);
      }
    })
  }

  return (
    <IonPage id="home-page">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Rüüm</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              Rüüm
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonList>
          {rooms ? rooms.map(r => <ListItem key={r.id} id={r.id} title={r.name} description={r.floor === -1 ? "Chäller" : r.floor === 0 ? "EG" : `${r.floor}. Stock`} onClick={() => onRoomClick(r.id)} />) : <IonLoading /> }
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Rooms;
