import axios from 'axios';

const request = axios.create({ baseURL: 'http://192.168.1.172:8081/api/v1/' });

request.interceptors.response.use(response => formatResponse(response));

function formatResponse(response) {
  return response.data;
}

export default request;