import ListItem from '../../components/ListItem/ListItem';
import { useState } from 'react';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter
} from '@ionic/react';
import './Sensors.css';
import { ApiClient } from '../../helpers';
import { useHistory, useParams } from 'react-router';

const Sensors = () => {

  const history = useHistory();
  const { roomId } = useParams();
  const [room, setRoom] = useState(null);
  const [sensors, setSensors] = useState(null);

  useIonViewWillEnter(refresh);
  
  function refresh() {
    ApiClient.get(`rooms`)
      .then(({rooms}) => setRoom(rooms.find(room => room.id == roomId)));
    ApiClient.get(`rooms/${roomId}/sensors`)
      .then(({sensors}) => {
        if(sensors.length === 1) {
          history.replace(`/rooms/${roomId}/sensors/${sensors[0].id}/values`);
        }
        setSensors(sensors);
      });
  }

  return (
    <IonPage id="home-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{room ? room.name : "Sensore"}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              {room ? room.name : "Sensore"}
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonList>
          {sensors ? sensors.length > 0 ? sensors.map(s => <ListItem key={s.id} id={s.id} title={s.name} path={`/rooms/${roomId}/senosrs/${s.id}/values`} />) : <IonLabel className="no-data-label">Keini Sensore in däm Ruum</IonLabel> : <IonLoading /> }
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Sensors;
