import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Line } from 'recharts';
import moment from 'moment';
import './ValueChart.css';

function ValueChart({ title, data, unit, stroke }) {
  return <div className="chart-section">
    <h5 className="chart-title">{title}</h5>
    <div className="chart">
      <ResponsiveContainer>
        <LineChart width="100%" height="100%" data={data}
          margin={{ top: 25, right: 30, left: 10, bottom: 35 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="timestamp" tickFormatter={tick => (moment(tick).fromNow())} angle={-20} tickMargin={10} />
          <YAxis domain={[dataMin => (dataMin - dataMin/100), dataMax => (dataMax + dataMax/100)]} tickFormatter={tick => (Math.round(tick))} unit={unit.symbol} />
          <Tooltip content={active => customTooltip(active, unit)} />
          <Line type="monotone" dataKey="value" stroke={stroke} dot={false} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  </div>
}

function customTooltip(active, unit) {
  const payload = active?.payload[0]?.payload;
  
  if (payload) {
    return (
      <div className="custom-tooltip">
        <span>{moment(payload.timestamp).format("DD[.] MMM YYYY HH:mm")}</span><br />
        <span>{unit.name}: <b>{payload.value}</b> {unit.symbol}</span>
      </div>
    )
  }
  return null;
}

export default ValueChart;