import { useState, useEffect } from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from '@ionic/react';
import { Storage } from '@capacitor/storage';

function TimeSettings() {

  let [selectedStartDate, setSelectedStartDate] = useState(null);
  let [selectedEndDate, setSelectedEndDate] = useState(null);

  useIonViewWillEnter(async () => {
    Promise.all([
      Storage.get({key: 'time_from'}),
      Storage.get({key: 'time_to'}),
    ]).then(timings => {
      if (timings[0].value) {
          setSelectedStartDate(timings[0].value);
        }
        if (timings[1].value) {
          setSelectedEndDate(timings[1].value);
        }
    })
  });

  function setStart(date) {
    let selectedStartDate = date;
    if (selectedStartDate) {
      Storage.set({
        key: 'time_from',
        value: selectedStartDate
      });
    } else {
      Storage.remove({key: 'time_from'});
    }
    setSelectedStartDate(selectedStartDate);
  }

  function setEnd(date) {
    let selectedEndDate = date;
    if (selectedEndDate) {
      Storage.set({
        key: 'time_to',
        value: selectedEndDate
      });
    } else {
      Storage.remove({key: 'time_to'});
    }
    setSelectedEndDate(selectedEndDate);
  }

  return <IonPage id="home-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Zyt Ihstellig</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonItem>
          <IonLabel>Start</IonLabel>
          <IonDatetime displayFormat="DD. MMMM YYYY" placeholder="Uswähle" value={selectedStartDate} onIonChange={e => {
            if (e.detail.value) {
              let d = new Date(e.detail.value);
              d.setHours(0);
              d.setMinutes(0);
              d.setSeconds(0);
              d.setMilliseconds(0);
              setStart(d.toISOString());
            } else {
              setStart(null);
            }
          }}></IonDatetime>
        </IonItem>
        <IonItem>
          <IonLabel>oder </IonLabel>
          <IonButton onClick={() => setSelectedStartDate(undefined)}>vo hüt am Morge</IonButton>
        </IonItem>
        <IonItem>
          <IonLabel>Änd</IonLabel>
          <IonDatetime displayFormat="DD. MMMM YYYY" placeholder="Uswähle" value={selectedEndDate} onIonChange={e => {
            if (e.detail.value) {
              let d = new Date(e.detail.value);
              d.setHours(0);
              d.setMinutes(0);
              d.setSeconds(0);
              d.setMilliseconds(0);
              setEnd(d.toISOString());
            } else {
              setEnd(null);
            }
          }}></IonDatetime>
        </IonItem>
        <IonItem>
          <IonLabel>oder </IonLabel>
          <IonButton onClick={() => setSelectedEndDate(undefined)}>bis jetzt</IonButton>
        </IonItem>
      </IonContent>
    </IonPage>
}

export default TimeSettings;