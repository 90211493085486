import { useEffect, useState } from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
  useIonViewDidLeave,
} from '@ionic/react';
import { ApiClient } from '../../helpers';
import { useHistory, useParams } from 'react-router';
import ValueChart from '../../components/ValueChart.jsx/ValueChart';
import LoadingIndicator from '../../components/LoadingIndicator';
import { IoTimeOutline } from 'react-icons/io5';
import { Storage } from '@capacitor/storage';
import './Values.css';

const strokeColors = [
  "#40798C",
  "#A53860",
  "#FFEEDB"
]
function Values() {

  let history = useHistory();

  const { roomId, sensorId } = useParams();
  const [room, setRoom] = useState(null);
  const [values, setValues] = useState(null);
  const [units, setUnits] = useState(null);
  const [noData, setNoData] = useState(false);

  useIonViewWillEnter(refresh);
  useIonViewDidLeave(() => {
    setNoData(false);
    setValues(null);
  });

  async function refresh() {
    setNoData(false);
    setValues(null);
    ApiClient.get(`rooms`)
      .then(({rooms}) => setRoom(rooms.find(room => room.id == roomId)));
      Promise.all([
        Storage.get({key: 'time_from'}),
        Storage.get({key: 'time_to'})
      ]).then(timings => {
        let params = {};
        if (timings[0].value) {
          params = { ...params, time_from: timings[0].value}
        } else {
          let d = new Date();
          d.setHours(0);
          d.setMinutes(0);
          d.setSeconds(0);
          d.setMilliseconds(0);
          params = { ...params, time_from: d.toISOString() }
        }
        if (timings[1].value) {
          params = { ...params, time_to: timings[1].value}
        }
        ApiClient.get(`rooms/${roomId}/sensors/${sensorId}/values`, { params })
          .then(({values}) =>  values.length > 0 ? setValues(values) : setNoData(true));
    });
    ApiClient.get('units').then(({units}) => setUnits(units));
  }

  return <IonPage id="home-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{room ? room.name : "Values"}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => history.push('/time_settings')}>
              <IoTimeOutline />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              {room ? room.name : "Values"}
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonList>
          {values && units ? units.map((unit, index) => <ValueChart data={values.filter(value => value.unit_id == unit.id)} unit={unit} stroke={strokeColors[index]} />) : noData ? <IonLabel className="no-data-label">Do gits no keini Date</IonLabel> : <LoadingIndicator />}
        </IonList>
      </IonContent>
    </IonPage>
}

export default Values;