import {
  IonItem,
  IonLabel,
  IonNote,
  IonSpinner
} from '@ionic/react';
import './ListItem.css';
import { IoChevronForward } from 'react-icons/io5';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

const ListItem = ({ id, title, description, path, onClick }) => {

  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    history.listen(() => setLoading(false));
  }, []);
  function onItemClicked() {
    setLoading(true);
    onClick();
  }

  return (
    <IonItem key={id} onClick={onItemClicked} routerLink={path} detail={false}>
      <IonLabel className="ion-text-wrap">
        <h2>{title}</h2>
        <h3>{description}</h3>
      </IonLabel>
      <IonNote style={{height: '100%', display: 'flex', alignItems: 'center'}}>
        { !loading ? <IoChevronForward /> : <IonSpinner /> }
      </IonNote>
    </IonItem>
  );
};

export default ListItem;
